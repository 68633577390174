
import './App.css';
import React, { Component } from 'react';
import { Pagination } from 'react-instantsearch-dom';

import { Container, Row } from 'react-grid-system';


import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectHitInsights, Configure,
    Hits,connectSearchBox  } from 'react-instantsearch-dom';
const aa = require('search-insights');

aa('init', {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
    useCookie: true
});




const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
);







const searchClient = {
    ...algoliaClient,
    search(requests) {
     // window.REACT_APP_FILTER = 'country: Mexico';
      return algoliaClient.search(requests);
    },
  };


  const index = searchClient.initIndex('b2bconnect-products-search-staging')


class App extends Component {

    resultQ;
    index_name;
    url_product;
    constructor(props) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.resultQ = urlParams.get('q')
        this.index_name = window.REACT_APP_ALGOLIA_INDEX;
        window.REACT_APP_Placeholder_Multilanguage = 'Search any product'
         this.url_product = window.REACT_APP_PRODUCT_URLBASE;
         //window.REACT_APP_PRODUCT_URLBASE = 'app.b2b-dev.northek.ca';
        //this.index_name = 'b2bconnect-products-search-staging';
        // this.url_product = 'app.b2b-dev.northek.ca';
        //window.REACT_APP_FILTER = 'country: Mexico';
        

    }

render() {


    return (
        <div className="ais-InstantSearch">
            <InstantSearch indexName={this.index_name} searchClient={searchClient}>
                <div className="right-panel">
                    {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                    <CustomSearchBox  defaultRefinement = { this.resultQ }
                                      refineOnMount={ true } />
                    <Hits hitComponent={HitWithInsights}/>
                   <Row justify={"center"}>
                        <Pagination
                            showFirst={true}
                            showLast={true}
                            showPrevious={true}
                            showNext={true}
                        />
                    </Row>
                </div>
                <Configure clickAnalytics = {true}  filters={window.REACT_APP_FILTER}/>
            </InstantSearch>
        </div>
    )
}

}




 const HitsCustom = ({ hit, insights }) => (
    <div>
     {hit.model_type == 'Product' && 
        <div className="card  card_search rounded-lg" >   
             { hit.imgURLs[0] ? (<img className="card-img-top rounded-top" onError={(e)=>{e.target.onerror = null; e.target.src="/assets/general/no-product.1d3abbb6.png" }} src={hit.imgURLs[0]} align="left" alt={hit.name} />) :
             (<img className="card-img-top rounded-top" src="/assets/general/no-product.1d3abbb6.png"  align="left" alt={hit.name} />)}
            <div className="card-body">
                <div className=" title-short text-left">
                    <b className="card-title">
                      { hit.name}
                    </b>
                </div>
                <span className="small text-left">
                    {hit.description} 
                </span>
            </div>
            <div className="card-footer rounded-lg border-0 bg-white">
                <a target="_blank" onClick={() =>
                    insights('clickedObjectIDsAfterSearch', {
                        eventName: 'Product Clicked'
                    }) }
                    className="btn mt-2 ml-2 btn-sm text-white border-0" href={ 'https://' + window.REACT_APP_PRODUCT_URLBASE + '/public/product/' + cutIds(hit.objectID)}>
                     Detalles
                 </a>
            </div>
        </div>
     }

     {hit.model_type == 'Business' && 
        <div className="card  card_search  card_providers rounded-lg" > 
             { hit.company.imgUrl ? (<img className="card-img-top rounded-top" onError={(e)=>{e.target.onerror = null; e.target.src="/assets/general/no-product.1d3abbb6.png" }} src={hit.company.imgUrl} align="left" alt={hit.company.name} />) :
             (<img className="card-img-top rounded-top" src="/assets/general/no-product.1d3abbb6.png"  align="left" alt={hit.company.name} />)}
            <div className="card-body">
                <div className=" title-short text-left">
                    <b className="card-title">
                        { hit.company.name}
                    </b>
                </div>
                <span className="small text-left">
                    {hit.company.description} 
                </span>
            </div>
            <div className="card-footer rounded-lg border-0 bg-white">
                <a target="_blank" onClick={() =>
                    insights('clickedObjectIDsAfterSearch', {
                    eventName: 'Product Clicked'
                    }) }
                 className="btn mt-2 ml-2 btn-sm text-white border-0" href={ 'https://' + window.REACT_APP_PRODUCT_URLBASE + '/public/provider/' + cutIds(hit.objectID)}>
                    Detalles
                </a>
            </div>
        </div>
      }
    </div>    
); 



const HitWithInsights = connectHitInsights(aa)(HitsCustom);


const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    <Container className="mb-5 mt-3">
        <div className="input-group ">
            <input
                id="searchVal"
                className="form-control"
                value={currentRefinement}
                onChange={event => {
                    refine(event.currentTarget.value);
                    // eslint-disable-next-line no-restricted-globals
                    history.pushState('','', "?q=" + event.currentTarget.value);
                }}
                placeholder={window.REACT_APP_Placeholder_Multilanguage}
            />
            <div className="input-group-prepend ">
                <button className="input-group-text cleanButton rounded-right" id="basic-addon1" onClick={() => refine('')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </button>
            </div>
        </div>
    </Container>
);

const CustomSearchBox = connectSearchBox(SearchBox);


const cutIds = (id) => {
    const result = id.split('-');
    return result[1];
}


export default App;
