

/*import { BrowserRouter, HashRouter } from 'react-router-dom';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render((
    <BrowserRouter>
        <App /> {/!* The various pages will be displayed by the `Main` component. *!/}
    </BrowserRouter>
), document.getElementById('root')
   );*/


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
